



























































































































































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { Import, Maybe, SystemJob } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

import RunJob from '../components/systemJobs/RunJob.vue';
import UiSelect from '../components/ui/UiSelect.vue';

@Component({
  components: {
    UiDataTable,
    UiSelect,
    RunJob,
    UiLoading,
    UiTooltip,
    UiButton,
    UiModal,
  },
  apollo: {
    getSystemJobs: {
      query: gql`
        query SystemJobs($orgId: ID!, $limit: Int, $paginationOption: String) {
          getSystemJobs(orgId: $orgId, limit: $limit, paginationOption: $paginationOption) {
            nextPageToken
            items {
              type
              id
              createdByUser {
                userId
                name
                email
              }
              cancelledByUser {
                userId
                name
                email
              }
              createdSEC
              statusUpdatedSEC
              startedSEC
              completedSEC
              status
              steps {
                id
                order
                status
                description
                startedSEC
                completedSEC
                successMessage
                errors
              }
              progress {
                numerator
                denominator
                units
              }
              name
              params {
                name
                value
              }
              errors
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          limit: typeof this.limit === 'string' ? Number(this.limit) : this.limit,
          paginationOption: this.paginationToken,
        };
      },
      result(result) {
        if (result.data.getSystemJobs) {
          this.systemJobsData = result.data.getSystemJobs.items;
          const pageToken = result.data.getSystemJobs.nextPageToken;
          console.log(pageToken);
          const split = pageToken.split('||');
          if (split.length === 2) {
            this.pagination.erlierPageToken = split[1];
            this.pagination.laterPageToken = split[0];
          }

          if (this.systemJobsData.length < this.limit) {
            this.earlierDisabled = true;
          } else {
            this.earlierDisabled = false;
          }
        }
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
    imports: {
      query: gql`
        query imports($orgId: ID!, $importType: String) {
          imports(orgId: $orgId, importType: $importType) {
            id
            status
            createdMS
            importErrors
            progress
            createdByUser {
              userId
              name
              email
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          importType: 'categorize',
        };
      },
      loadingKey: 'isLoading',
      pollInterval: 3000,
    },
  },
})
export default class SystemJobs extends BaseVue {
  pagination = {
    page: 1,
    erlierPageToken: '',
    laterPageToken: '',
  };

  //  constants
  readonly headers = [
    {
      id: 'jobId',
      label: 'Job Id',
      defaultVisibility: true,
    },
    {
      id: 'name',
      label: 'Job Name',
      defaultVisibility: true,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'createdBy',
      label: 'Created By',
      defaultVisibility: true,
    },
    {
      id: 'createdSEC',
      label: 'Created On',
      defaultVisibility: true,
    },
    {
      id: 'params',
      label: 'Parameters',
      defaultVisibility: true,
    },
    {
      id: 'startedSEC',
      label: 'Started On',
      defaultVisibility: false,
    },
    {
      id: 'completedSEC',
      label: 'Completed On',
      defaultVisibility: false,
    },
  ];

  // readonly pageSizes = pageSizes;
  isLoading = 0;
  importResultsView: Import | null = null;

  //  Pagination
  paginationToken = '';
  earlierDisabled = false;
  laterDisabled = true;

  hasMore = false;
  limit = '10';

  // data
  systemJobsData = [] as SystemJob[];
  imports = [] as Import[];

  public refresh() {
    this.$apollo.queries.getSystemJobs.refetch();
  }

  public earlier() {
    if (!this.earlierDisabled) {
      this.pagination.page++;
      this.laterDisabled = false;
      this.paginationToken = `earlier||${this.pagination.erlierPageToken}`;
      console.log(this.paginationToken);
    }
  }

  public later() {
    if (!this.laterDisabled) {
      this.pagination.page--;
      if (this.pagination.page === 1) {
        this.laterDisabled = true;
      } else {
        this.laterDisabled = false;
      }
      this.earlierDisabled = false;
      this.paginationToken = `later||${this.pagination.laterPageToken}`;
      console.log(this.paginationToken);
    }
  }

  public getWalletName(walletId: string): Maybe<string> | undefined {
    const walletsArray = this.$store.state.wallets.wallets;
    const wallet = walletsArray?.find((w) => w.id === walletId);
    if (wallet) {
      return wallet.name;
    }

    return walletId;
  }

  public getErrors(errors: string[]): string {
    if (errors) {
      const header = 'Errors: \n';
      return errors.reduce((prev, curr) => `${prev}\n${curr}`);
    }
    return 'Error';
  }

  public get combinedEntries(): any[] {
    const categorizeJobs = this.imports.map(
      (x) =>
        ({
          id: x.id,
          createdSEC: (x.createdMS ?? 0) / 1000,
          name: 'Categorize Transactions',
          params: [],
          status: x.status,
          createdByUser: x.createdByUser,
          importSource: x,
        } as SystemJob)
    );
    return this.systemJobsData.concat(categorizeJobs).sort((a, b) => {
      return (b.createdSEC ?? 0) - (a.createdSEC ?? 0);
    });
  }
}
