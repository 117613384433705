import axios from 'axios';

async function upload(formData: any, uploadUrl: string, config?: any) {
  const resp = await axios.put(uploadUrl, formData, {
    ...config,
  });

  if (resp.status === 200) {
    return true;
  }
  return false;
}

export { upload };
