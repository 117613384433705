




























































import axios from 'axios';
import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import { upload } from '@/services/fileUploadService';

import { downloadCsv } from '../data/dataUtils';
import UiButton from '../ui/UiButton.vue';
import UiDatePicker from '../ui/UiDatePicker.vue';
import UiLoading from '../ui/UiLoading.vue';
import UiModal from '../ui/UiModal.vue';
import UiSelect2 from '../ui/UiSelect2.vue';

@Component({
  components: {
    UiCheckbox,
    UiSelect2,
    UiDatePicker,
    UiButton,
    UiLoading,
    UiModal,
  },
})
export default class CategorizeJob extends BaseVue {
  public categorizeCSVFile = null as File | null;
  public categorizeLoading = false;
  public loadingProgress = 0;

  public async downloadCategorizeTemplate() {
    const columns = [
      '1. Transaction ID (Mandatory)',
      '2. Transaction Line ID (Optional)',
      '3. Category ID (Mandatory)',
      '4. Contact ID (Mandatory)',
      '5. Fee Category ID (Optional)',
      '6. Fee Contact ID (Optional)',
    ];
    downloadCsv('"' + columns.join('","') + '"\n', 'categorize-template');
  }

  public categorizeCSVChanged(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    this.categorizeCSVFile = files?.[0] ?? null;
    console.log(files);
  }

  public async processCategorizeCSV() {
    await this.upload();
  }

  async upload() {
    this.categorizeLoading = true;
    this.loadingProgress = 0;

    try {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $importType: String) {
            createImport(orgId: $orgId, importType: $importType) {
              id
              fileUploadUrl
            }
          }
        `,
        variables: {
          orgId: this.$store.state.currentOrg.id,
          importType: 'categorize',
        },
      });
      this.loadingProgress = 10;
      if (res && res.data) {
        const uploadUrl = res.data.createImport.fileUploadUrl;
        const resp = await axios.put(uploadUrl, this.categorizeCSVFile, {
          onUploadProgress: (progressEvent) => {
            this.loadingProgress = (progressEvent.loaded / progressEvent.total) * 80 + 10;
          },
        });

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!) {
              runImport(orgId: $orgId, id: $id)
            }
          `,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            id: res.data.createImport.id,
          },
        });

        this.loadingProgress = 100;
        this.$emit('closeDialog');
      }
    } catch (e) {
      console.error(e);
      this.showErrorSnackbar('Unable to upload file');
    } finally {
      this.categorizeLoading = false;
      this.loadingProgress = 0;
      this.categorizeCSVFile = null;
    }
  }
}
