interface Field {
  name: string;
  type: string;
}

interface JsonData {
  fields: Field[];
}
export function downloadCsv(csvContent: string, fileName: string): void {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName + '.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function jsonToCsv(jsonData: JsonData): string {
  const fields = jsonData.fields.map((field: { name: any }) => field.name);

  // Create the CSV content with the header
  let csvContent = fields.join(',') + '\n';

  // Create CSV rows for each field
  // You may have your own data source here, or this is just a sample
  // You can modify the data accordingly based on your use case
  for (let i = 0; i < 5; i++) {
    const rowData = fields.map((field: string) => `"${field}_${i}"`).join(',');
    csvContent += rowData + '\n';
  }

  return csvContent;
}
